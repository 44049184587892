<template>
  <div style="height: 100vh; width: 100vw;">
    <div @click="$router.push('/')" style="padding: 20px 0 0 20px;font-weight: bolder; color: white;font-size: 20px; cursor: pointer;">
      Mortgage Envy
    </div>
    <div
      v-if="loaded"
      class="d-flex justify-content-center align-items-center full-height"
    >
      <div
        class="col-5 align-self-center"
      >
        <app-collapse
          accordion
          type="margin"
        >
          <div
            class="mt-2"
          >
            <app-collapse-item
              :title="questions.questionOne.question"
            >
              <b-list-group flush>
                <hwa-list-item
                  v-for="(ans,index) in questions.questionOne.answers"
                  :key="index"
                  class="animate__animated animate__fadeIn"
                  :title="ans.title"
                  :selected="ans.selected"
                  @click.native="onSelect(1,index)"
                />
              </b-list-group>
            </app-collapse-item>
          </div>
        </app-collapse>
        <div class="mt-2" />

        <div class="d-flex">
          <hwa-button
            icon="ArrowLeftCircleIcon"
            label="PREVIOUS"
            @onClick="$router.back()"
          />
          <div class="ml-1" />
          <hwa-button
            :disabled="!valid"
            label="NEXT"
            :loading="nextLoading"
            @onClick="onNext()"
          />
        </div>
      </div>
    </div>
    <div
      v-else
      class="d-flex justify-content-center align-items-center full-height"
    >
      <grid-loader
        :loading="true"
        color="white"
      />

    </div>
  </div>
</template>

<script>
import { BListGroup, BSpinner } from 'bootstrap-vue'
import HwaListItem from '@/layouts/components/HwaListItem.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import HwaInput from '@/layouts/components/HwaInput.vue'
import HwaButton from '@/layouts/components/HwaButton.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import GridLoader from 'vue-spinner/src/GridLoader.vue'

export default {
  name: 'StartPurchase',
  components: {
    HwaButton,
    HwaInput,
    HwaListItem,
    BListGroup,
    AppCollapse,
    AppCollapseItem,
    BSpinner,
    GridLoader,
  },
  data() {
    return {
      loaded: false,
      nextLoading: false,
      questions: {
        questionOne: {
          id: null,
          question: '',
          answers: [],
          userProvidedAns: {},
        },
      },
    }
  },
  computed: {
    valid() {
      return this.questions.questionOne.answers.some(q => q.selected)
    },
  },
  mounted() {
    this.getStepFourQuestions()
  },
  methods: {
    onSelect(type, index) {
      if (type === 1) {
        this.selectedAnswer = index
      }
      switch (type) {
        case 1:
          this.questions.questionOne.answers = this.questions.questionOne.answers.map(q => ({
            ...q,
            selected: false,
          }))
          this.questions.questionOne.answers[index].selected = !this.questions.questionOne.answers[index].selected
          break
      }
    },
    onNext() {
      const id = localStorage.getItem('anonymous')
      this.nextLoading = true
      const completed = this.$store.getters['auth/isLoggedIn']
      this.$http.post(`api/questions/refinance/4/${id}?application_id=${this.$store.state.auth.applicationId}&completed=${completed}`, this.questions)
        .then(res => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckCircleIcon',
              text: 'First step done, lets move on.',
              variant: 'success',
            },
          })
          this.$store.state.auth.stepOneCompleted = true
          this.nextLoading = false
          if (completed) {
            this.$router.push('/')
          } else {
            this.$router.push({ name: 'complete', params: { type: 'refinance' } })
          }
        })
        .catch(err => {
          this.nextLoading = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed',
              icon: 'CheckCircleIcon',
              text: 'Something went wrong, try again.',
              variant: 'danger',
            },
          })
        })
    },
    getStepFourQuestions() {
      const id = localStorage.getItem('anonymous')
      this.$http.get(`api/questions/refinance/4/${id}?update=${this.$store.state.auth.updateRequest}&application_id=${this.$store.state.auth.applicationId}`)
        .then(res => {
          this.questions = res.data
          this.loaded = true
          this.questions.questionOne.answers.forEach((a, index) => {
            if (a.selected) {
              this.selectedAnswer = index
            }
          })
        })
    },
  },
}
</script>

<style scoped>

</style>
